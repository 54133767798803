/**
 * A basic argument parser
 */
function updateLong(key, parameters) {
    for (const name in parameters) {
        const p = parameters[name];
        if (p.long === key) {
            parameters[name].name = name;
            parameters[name].value = true;
            return parameters;
        }
    }
    throw new Error('unknown argument: '+key);
}
function updateShort(key, parameters) {
    for (const name in parameters) {
        const p = parameters[name];
        if (p.short === key) {
            parameters[name].name = name;
            parameters[name].value = true;
            return parameters;
        }
    }
    throw new Error('unknown argument: '+key);
}
function processArguments(args, parameters, positional) {
    for (let i = 0, p = 0; i < args.length; i++) {
        const value = args[i];
        if (value[0] === '-') {
            if (value[1] === '-') {
                // long form
                parameters = updateLong(value.substring(2), parameters);
                continue;
            }
            // short form
            for (let c = 1; c < value.length; c++) {
                parameters = updateShort(value[c], parameters);
            }
            continue;
        }
        // positional arg
        if (p < positional.length) {
            positional[p].value = value;
            p++;
        } else {
            throw new Error('too many positional args');
        }
    }
}
function checkArguments(parameters, positional) {
    // verify parameters
    Object.values(parameters).forEach(({ name, type, value, required=false }) => {
        if (required && ! value) {
            switch (type) {
                case 'toggle': break;
                default: throw new Error('Missing parameter: '+name);
            }
        }
    });
    // verify required positional args
    positional.forEach(({ name, value, required=true }) => {
        if (required && ! value) {
            throw new Error('Missing positional argument: '+name);
        }
    });
}
function reduceArguments(parameters, positional) {
    console.log('test', parameters, positional);
    let result = {};
    Object.values(parameters).forEach(({ name, value }) => {
        result[name] = value;
    });
    positional.forEach(({ name, value }) => {
        result[name] = value;
    });
    return result;
}
export default function parseArguments([command, ...args], context) {
    processArguments(args, context.parameters, context.positional);
    checkArguments(context.parameters, context.positional);
    return reduceArguments(context.parameters, context.positional);
}