/**
 * Shutdown command
 * 
 * Usage: shutdown [-akrhPHfFnc] [-t sec] time [warning message]
 * Alias: reboot -> shutdown -r 0
 */
import ms from 'ms';
import defineCommand from './command';

const argConfig = {
    'parameters': {
        'fake': {
            short: 'k',
            type: 'toggle',
            message: 'Don\'t really shutdown; only send the warning messages to everybody.'
        },
        'reboot': {
            short: 'r',
            type: 'toggle',
            message: ' Reboot after shutdown.'
        },
        'halt': {
            short: 'h',
            type: 'toggle',
            message: 'Halt or power off after shutdown.'
        },
        'cancel': {
            short: 'c',
            type: 'toggle',
            message: 'Cancel a waiting shutdown.'
        },
    },
    'positional': [
        { name: 'time' },
        { name: 'warning', required: false },
    ]
};
export default defineCommand(argConfig, async function (config) {
    const delay = config.time === 'now' ? 0/*supposed to be 0 but ya know*/ : 
        ms(/^\d+$/.test(config.time) ? config.time + 's' : config.time);
    setTimeout(() => {
        if (config.reboot) {
            location.reload();
        }
    }, delay+100);
    console.log(config);
    if (config.reboot) {
        return `Rebooting in ${ms(delay)}!`;
    } else {
        return `Shutting down in ${ms(delay)}!`;
    }
});