/**
 * Command abstract
 */
import parseArguments from "../utils/arg-parse";

export default function defineCommand({ parameters={}, positional=[] }, handler) {
    const argConfig = { parameters, positional };
    return async function handlerProxy(options, wasmFs) {
        try {
            const config = parseArguments(options.args, argConfig);
            return await handler(config, options, wasmFs);
        } catch(err) {
            return `Error: ${err.message}`;
        }
    }
}