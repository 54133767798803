/**
 * Basic last login storage
 */
const STORAGE_KEY = 'last_login';

// function formatDate(timestamp) { // because dealing with time is still ridiculous
//     const d = new Date(timestamp);
//     const day = ['Mon','Tue','Wed','Thu','Fri'][d.getDay()-1];
//     const month = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'][d.getMonth()];
//     const hours = d.getHours() < 10 ? '0'+d.getHours() : d.getHours();
//     const minutes = d.getMinutes() < 10 ? '0'+d.getMinutes() : d.getMinutes();
//     const seconds = d.getSeconds() < 10 ? '0'+d.getSeconds() : d.getSeconds();
//     return `${day} ${month} ${d.getDate()} ${hours}:${minutes}:${seconds} ${d.getFullYear()}`;
// }

export default function getLastLogin() {
    const now = +new Date();
    const last = +localStorage.getItem(STORAGE_KEY) || now;
    localStorage.setItem(STORAGE_KEY, now);
    return ''+new Date(last);//formatDate(last);
}