import { green, reset } from "./ansi";

export const art = version => ` _____  _                  _   ______                              
|  __ \\| |                | | |  ____|                             
| |__) | | __ _ _ __   ___| |_| |__  __  ___ __  _ __ ___  ___ ___ 
|  ___/| |/ _\` | '_ \\ / _ \\ __|  __| \\ \\/ / '_ \\| '__/ _ \\/ __/ __|
| |    | | (_| | | | |  __/ |_| |____ >  <| |_) | | |  __/\\__ \\__ \\
|_|    |_|\\__,_|_| |_|\\___|\\__|______/_/\\_\\ .__/|_|  \\___||___/___/
       Planetary Interactive Shell        | |   ${green}${version}${reset}
                                          |_|
`;

export function banner(version) {
    return `${art(version)}  # Examples:
    cowsay "good news everyone!"
    help`;
}