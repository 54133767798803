// import ShortUniqueId from 'short-unique-id';
// import whatBrowser from './utils/find-browser';
// const uid = new ShortUniqueId({ length: 8 });
// term.write(`\x1B[1;3;32mBrainShell\x1B[0m /users/${whatBrowser()}/${uid()} \$ `);

import WasmTerminal, { fetchCommandFromWAPM } from "@wasmer/wasm-terminal";
import { lowerI64Imports } from "@wasmer/wasm-transformer";
import { magenta, green, reset } from "./ansi";
import { banner } from "./banner";
import helpCommand from "./commands/help";
import lsCommand from "./commands/ls";
import rmCommand from "./commands/rm";
import notFoundCommand from "./commands/not-found";
import shutdownCommand from "./commands/shutdown";
import getLastLogin from "./utils/last-login";

const COMMANDS = {
  'help': helpCommand,
  'shutdown': shutdownCommand,
  'ls': lsCommand,
  'rm': rmCommand,
};

const ALIASES = {
  'reboot': ['shutdown', '-r', 'now'],
};

// Let's write handler for the fetchCommand property of the WasmTerminal Config.
const fetchCommandHandler = async ({args}) => {
  let commandName = args[0];
  // check commands
  if (commandName in COMMANDS) {
    return COMMANDS[commandName];
  }
  // check aliases
  if (commandName in ALIASES) {
    return await fetchCommandHandler({ args: ALIASES[commandName] });
  }
  // not found fallback
  // return notFoundCommand;
  // Let's fetch a wasm Binary from WAPM for the command name.
  const wasmBinary = await fetchCommandFromWAPM({args});
  // lower i64 imports from Wasi Modules, so that most Wasi modules
  // Can run in a Javascript context.
  return await lowerI64Imports(wasmBinary);
};

// Let's create our Wasm Terminal
const wasmTerminal = new WasmTerminal({
  // Function that is run whenever a command is fetched
  fetchCommand: fetchCommandHandler
});

// Let's print out our initial message
wasmTerminal.print(`Last login: ${magenta}${getLastLogin()}${reset}
${banner(green+'Brainslux 5.17.3'+reset)}`);

// Let's bind our Wasm terminal to it's container
wasmTerminal.open(document.getElementById('terminal'));
wasmTerminal.fit();
wasmTerminal.focus();

// console.log(wasmTerminal.xterm);
// wasmTerminal.xterm.write(`\x1B[1;3;32mBrainShell\x1B[0m /users/${whatBrowser()}/${uid()} \$ `);
// wasmTerminal.wasmTty.setPromptPrefix('test');
// Later, when we are done with the terminal, let's destroy it
// wasmTerminal.destroy();